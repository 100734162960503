import styled, { css, createGlobalStyle } from 'styled-components'
import tw from 'twin.macro'
import { ButtonLink } from '../../base'


export const ArticleListFixedGlobalStyle = createGlobalStyle`
  .article-list {
    margin-top: 40px;
  }

  @media (max-width: 767px) {
    .article-list {
      margin-top: 56px;
    }
  }
`

export const MenuWrapper = styled.nav<{
  isFixedPosition?: boolean
  hideNav?: boolean
  hideMenu?: boolean
}>`
  ${tw`relative w-full flex justify-center flex-wrap z-30 max-w-full bg-blue__deep text-white`}

  ${({ hideMenu }) => {
    if (hideMenu) {
      return tw`hidden`
    }
  }}

  ${({ isFixedPosition, hideNav }) => {
    if (isFixedPosition) {
      return css`
        position: fixed;
        ${!hideNav ? `top: 80px;` : `top: 0;`}

        @media (max-width: 767px) {
          ${!hideNav ? `top: 95px;` : `top: 0;`}
        }
      `
    }
  }}
`

export const SubMenuWrapper = styled.nav<{
  isFixedPosition?: boolean
}>`
  ${tw`relative w-full flex justify-center flex-wrap z-30 max-w-full`}

  ${({ isFixedPosition }) => {
    if (isFixedPosition) {
      return css`
        position: fixed;
        top: 80px;
        @media (max-width: 1024px) {
          top: 80px;
        }

        @media (max-width: 767px) {
          top: 95px;
        }
      `
    }
  }}
`

export const MenuItems = styled.div<{ mobileShowed?: boolean }>`
  ${tw`md:flex flex-1 justify-center flex-wrap flex-col md:flex-row w-full`}
  ${({ mobileShowed }) => (mobileShowed ? tw`flex` : tw`hidden`)}
`

export const MenuItem = styled.a<{
  isActive?: boolean
  isSearchIcon?: boolean
}>`
  ${tw`md:py-2 md:px-8 px-4 py-2 md:text-center uppercase font-display cursor-pointer text-white transition duration-300`}

  &:hover {
    ${tw`bg-white text-blue__deep`}
  }

  ${({ isActive }) => {
    return isActive && tw`bg-white text-blue__deep`
  }}

  ${({ isSearchIcon }) => {
    return isSearchIcon && tw`hidden md:block`
  }}
`

export const AdditionalMenuStyled = styled.div<{ displaySearch?: boolean }>`
  ${tw`relative w-full overflow-hidden`}
  &.search-form-enter {
    left: 100%;
    width: 0;
    transition: width 400ms, left 400ms, height 300ms;
  }
  &.search-form-enter-active {
    left: 0;
    width: 50%;
    transition: width 400ms, left 400ms, height 300ms;
  }
  &.search-form-exit {
    left: 0;
    width: 50%;
    transition: width 400ms, left 400ms, height 300ms;
  }
  &.search-form-exit-active {
    left: 100%;
    width: 0;
    transition: width 400ms, left 400ms, height 300ms;
  }
`
export const ButtonWrapper = styled.div`
  ${tw`w-full flex md:hidden items-stretch`}
`
export const ButtonSubWrapper = styled.div`
  ${tw`w-full flex lg:hidden items-stretch`}
`
export const ButtonStyled = styled(ButtonLink)`
  ${tw`flex-1 text-center`}
`

export const MenuSearchWrapper = styled.div<{ fixedPosition: boolean }>`
  ${tw`w-full overflow-y-hidden`}
  bottom: 100%;

  ${({ fixedPosition }) => (fixedPosition ? '' : tw`absolute`)}
`
